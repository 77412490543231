// SkillsRoute.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import Sidebar from "../components/Sidebar";

const skillsArray = [
  {
    name: "JavaScript",
    type: "Fullstack",
    use: "Mainly used for front-end, currently learning how to use it for back-end projects.",
    dotColor: "#f7df1e",
    progress: 42,
  },
  {
    name: "React",
    type: "Frontend",
    use: "I am currently learning react and using it for my front-end projects.",
    dotColor: "#61dafb",
    progress: 43,
  },
  {
    name: "Tailwind",
    type: "CSS",
    use: "Tailwind is a very good,light vanilla css replacement.",
    dotColor: "#38bdf8",
    progress: 46,
  },
];

function SkillsRoute() {
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSkillHover = (index) => {
    if (!isMobileMenuOpen) {
      setHoveredSkill(index);
    }
  };

  React.useEffect(() => {
    document.title = "Anas A - My skills";
  }, []);

  return (
    <div className="flex gap-10">
      <Sidebar toggleMobileMenu={toggleMobileMenu} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`py-20 w-full pr-10 ml-0 md:ml-[240px] ${
          isMobileMenuOpen ? "blurry-bg" : ""
        }`}
      >
        <div className="flex flex-col w-full flex-1 overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col flex-1">
            <h1 className="text-3xl font-bold text-black">My skills</h1>
            <h2 className="text-xl font-normal mb-5 text-black mt-5">
              My most used skills and languages
            </h2>
            <div className="flex flex-col">
              {skillsArray.map((item, index) => (
                <motion.div
                  className="mb-5 skill-block"
                  key={index}
                  onMouseEnter={() => handleSkillHover(index)}
                  onMouseLeave={() => handleSkillHover(null)}
                  initial={{ opacity: 0, scale: 0.4 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.6,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  <div className="flex max-h-42 flex-col md:flex-row border rounded-xl">
                    <div className="flex flex-col border-bottom-proj border-right py-10 px-5">
                      <h5 className="text-md font-thin text-black">
                        <code>name : </code>
                      </h5>
                      <div className="flex flex-row w-28 items-center">
                        <h1 className="text-lg font-normal text-black mr-2">
                          {item.name}
                        </h1>
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: item.dotColor }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex flex-col p-5">
                      <h5 className="text-md font-thin text-black">
                        <code>type : </code>
                        {item.type}
                      </h5>
                      <h5 className="text-md font-thin text-black">
                        <code>use : </code>
                        {item.use}
                      </h5>
                      <div className="flex flex-row w-full items-center">
                        <div
                          className={`h-2 rounded ${
                            index === hoveredSkill
                              ? "bg-blue-500"
                              : "bg-gray-400"
                          }`}
                          style={{ width: `${item.progress}%` }}
                        ></div>
                        <div
                          className={`h-2 rounded ${
                            index === hoveredSkill
                              ? "bg-blue-200"
                              : "bg-gray-200"
                          }`}
                          style={{ width: `${100 - item.progress}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default SkillsRoute;
